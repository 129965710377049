.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 175px;
  background: $bg;
  @include media-up(xl) {
    padding: 0 10px;
  }
  @include media-down(md) {
    height: 165px;
  }
  @include media-down(sm) {
    height: 95px;
    padding: 15px 0;
  }

  &__container {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-down(lg) {
      padding: 0 10px;
    }
    @include media-down(xs) {
      padding: 0 20px;
    }
  }

  &__main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-down(md) {
      justify-content: flex-start;
    }
  }

  &__block {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: block;
    margin-right: 88px;
    width: 106px; height: 106px;
    svg, img {
      width: 100%; height: 100%;
    }
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -38px; top: -38px;
      width: 76px; height: 76px;
      border-radius: 100%;
      background: $bg;
      transition: transform .3s ease;
      @include media-down(md) {
        background: #fff;
      }
      @include media-down(sm) {
        width: 46px; height: 46px;
        left: -23px; top: -23px;
      }
    }
    &:hover:before {
      transform: scale(1.2);
    }
    @include media-down(md) {
      margin-right: 50px;
    }
    @include media-down(sm) {
      width: 65px; height: 65px;
      margin-right: 30px;
    }
  }

  &-nav {
    @include media-down(md) {display: none;}
    &__list {
      display: flex;
    }
    &__item {
      margin-right: 72px;
    }
    a {
      font-size: 18px;
      text-decoration: none;
      display: block;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 50%; bottom: -3px;
        transform: translateX(-50%);
        display: block;
        width: 0; height: 2px;
        background: #000;
        transition: $tnsn;
        pointer-events: none;
      }
      &:hover,
      &.active {
        &:before {width: 100%;}
      }
    }
  }

  &-socials {
    display: flex;
    @include media-down(sm) {display: none;}
    &__item {
      width: 44px; height: 44px;
      border: 1px solid $border;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      transition: $tnsn;
      img, svg {width: 18px; height: 18px;}
      path {transition: $tnsn;}
      &:hover {
        background: #000;
        path {fill: #fff;}
      }
    }
  }

  &-lang {
    width: 84px;
    height: 44px;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    position: relative;
    user-select: none;
    text-transform: uppercase;
    @include media-down(sm) {
      font-size: 13px;
      width: 65px; 
      height: 36px;
    }
    &__inner {
      position: absolute;
      left: 0; top: 0;
      width: 100%;
      border-radius: 22px;
      border: 1px solid $border;
      overflow: hidden;
      transition: box-shadow .2s;
      background: $bg;
      @include media-down(sm) {
        border-radius: 18px;
      }
    }
    &__current {
      width: 100%; height: 42px;
      padding: 14px 23px;
      cursor: pointer;
      position: relative;
      transition: background-color .2s ease, color .2s ease;
      @include media-down(sm) {
        padding: 9px 18px;
        height: 34px;
      }
      &:after {
        content: '';
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 5px solid #000;
        transition: border-left-color .2s ease, transform .2s ease;
        pointer-events: none;
        @include media-down(sm) {
          right: 12px;
        }
      }
      &:hover {
        background-color: #000;
        color: #fff;
        &:after {border-left-color: #fff;}
      }
    }
    &__dropdown {
      display: none;
      width: 100%;
    }
    &__item, li {
      width: 100%;
      height: 42px;
      padding: 14px 23px;
      cursor: pointer;
      border-top: 1px solid $border;
      transition: $tnsn;
      @include media-down(sm) {
        padding: 9px 18px;
        height: 34px;
      }
      &:hover {
        background: #000;
        color: #fff;
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
    &--active .header-lang {
      &__current:after {transform: translateY(-3px) rotate(90deg);}
      &__inner {box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);}
    }
  }
  
  &--fixed {
    position: fixed;
    top: 0;
    animation: showHeader .5s;
    width: 100%;
    height: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);

    .header__logo {
      height: 60px;
      &:before {
        width: 43px; height: 43px;
        left: 1px; top: -21px;
        @include media-down(sm) {
          left: -20px;
        }
      }
    }
  }
}

.hamburger {
  width: 30px; height: 30px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-up(lg) {display: none;}
  &-box {
    width: 25px; height: 18px;
    position: relative;
  }
  &-inner,
  &-inner:before,
  &-inner:after {
    position: absolute;
    left: 0; top: 0;
    width: 25px; height: 2px;
    background: #000000;
  }
  &-inner {
    transition: transform .3s ease, top .2s .3s;
  }
  &-inner:before,
  &-inner:after {
    content: '';
    display: block;
  }
  &-inner:before {
    width: 20px;
    left: 5px; top: 8px;
    transition: top .3s .2s, width .3s .2s, opacity .3s .2s;
  }
  &-inner:after {
    left: 0; top: 16px;
    transition: transform .3s ease, top .2s .3s;
  }
  &.active .hamburger {
    &-inner {
      top: 8px;
      transform: rotate(225deg);
      transition: top .2s, transform .3s .2s ease;
    }
    &-inner:after {
      top: 0;
      transform: rotate(90deg);
      transition: top .2s, transform .3s .2s ease;
    }
    &-inner:before {
      top: 0;
      width: 0;
      transition: top .2s, width .1s, opacity .1s;
    }
  }
}

.mobile-menu {
  position: fixed;
  z-index: 999;
  top: 0; right: -260px;
  width: 260px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: height .25s ease;
  background: $bg;
  padding: 165px 0 95px;
  @include media-down(sm) {
    padding: 95px 0 0;
  }
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 55px 50px 30px;
  }
  &__nav {
    li {
      margin-bottom: 40px;
      margin-right: 0;
    }
    a {
      text-decoration: none;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0; bottom: -3px;
        display: block;
        width: 0; height: 2px;
        background: #000;
        transition: $tnsn;
      }
      &:hover,
      &.active {
        &:before {width: 100%;}
      }
    }
  }
  &__socials {
    display: flex !important;
    flex-wrap: wrap;
  }
  &__title {
    font-weight: 500;
    margin-bottom: 35px;
  }
}
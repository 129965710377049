.projects-grid {
  width: 100%;
  position: relative;
  margin-bottom: 53px;
  display: block;
  @include media-down(sm) {
    display: flex;
    flex-direction: column;
  }
  &__container {
    @include media-down(sm) {
      max-width: 100%;
      padding: 0;
    }
  }
  .owl-item {
    height: calc(100vh - 95px);
    height: calc(var(--vh, 1vh) * 100 - 95px);
    transition: height .25s ease;
  }
  .owl-dots {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
  .owl-dot {
    width: 30px; height: 30px;
    padding: 13px 0;
    &:not(:last-child){margin-right: 10px;}
    span {
      display: block;
      width: 100%; height: 4px;
      background: #fff !important;
    }
    &.active span {background: #000 !important;}
  }
}

.project-item {
  visibility: hidden;
  display: block;
  text-decoration: none;
  position: relative;
  text-align: center;
  color: #fff;
  overflow: hidden;
  @include media-up(md) {
    margin-bottom: 20px;
    float: left;
  }
  @include media-up(lg) {
    margin-bottom: 30px;
    &:nth-of-type(6n + 1),
    &:nth-of-type(6n + 5) {
      width: calc(100% / 3 * 2 - 30px);
      height: calc(40vw - 94px);
    }
    &:nth-of-type(6n + 2),
    &:nth-of-type(6n + 3),
    &:nth-of-type(6n + 4),
    &:nth-of-type(6n + 6) {
      width: calc(100% / 3);
      height: 20vw;
    }
    &:nth-of-type(6n + 1),
    &:nth-of-type(6n + 4),
    &:nth-of-type(6n + 6) {
      margin-right: 30px;
    }
    &:nth-of-type(6n + 5) {
      float: right;
    }
  }
  @include media-only(md) {
    &:nth-of-type(2n + 1) {
      margin-right: 20px;
    }
    &:nth-of-type(6n + 1),
    &:nth-of-type(6n + 4),
    &:nth-of-type(6n + 5) {
      width: 440px; height: 340px;
    }
    &:nth-of-type(6n + 2),
    &:nth-of-type(6n + 3),
    &:nth-of-type(6n + 6) {
      width: 268px; height: 380px;
    }
    &:nth-of-type(6n + 1){
      height: 380px;
    }
    &:nth-of-type(1) {
      height: 340px;
    }
    &:nth-of-type(2) {
      height: 500px;
    }
    &:nth-of-type(6n + 6) {
      margin-top: -40px;
    }
  }
  @include media-down(sm) {
    width: 100vw;
    padding-top: 87.5%;
    &:not(:last-child){margin-bottom: 10px;}
  }

  &__logo {
    position: absolute;
    z-index: 0;
    left: 0; top: 0;
    width: 100%; height: 100%;
    img {
      width: 100%; height: 100%; 
      object-fit: cover;
      transition: 0.7s ease;
    }
  }
  &__overlay {
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    left: 0; top: 0;
    width: 100%; height: 100%;
    @include media-down(md) {
      left: 0 !important; top: 0 !important;
    }
  }
  &__content {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    max-width: 327px;
    max-height: calc(100% - 30px);
    overflow: hidden;
  }
  &__title {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    text-transform: uppercase;
    max-height: 30px;
    overflow: hidden;
    @include media-down(lg) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
      max-height: 48px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 30px;
  }
  &:hover .project-item {
    &__logo img {transform: scale(1.05);}
    &__overlay {opacity: 1;}
  }
}
.main-page {
  .projects-grid {
    margin-bottom: -30px;
    padding: 24px 0;
    @include media-down(md) {
      padding: 0;
    }
    @include media-down(sm) {
      overflow: hidden;
      padding: 0;
    }
  }
  .project-item {
    @include media-up(lg) {
      &:nth-child(n + 4) {display: none;}
      &:nth-of-type(n + 1){
        height: calc(100vh - 318px);
      }
      &:nth-of-type(n + 2),
      &:nth-of-type(n + 3) {
        height: calc((100vh - 348px) / 2);
      }
    }
    @include media-only(md) {
      &:nth-of-type(n + 1) {
        width: 470px;
        height: calc((100vh - 245px) / 3 * 2 - 10px);
      }
      &:nth-of-type(n + 2) {
        width: 228px;
        height: calc((100vh - 245px) / 3 * 2 - 10px);
      }
      &:nth-of-type(n + 3) {
        width: 228px;
        height: calc((100vh - 245px) / 3 - 10px);
      }
      &:nth-of-type(n + 4) {
        width: 470px;
        height: calc((100vh - 245px) / 3 - 10px);
      }
    }
    @include media-down(sm) {
      padding: 0;
      height: 100%;
    }
  }
}
@import url(../libs/owlcarousel/owl.theme.default.min.css);
@import url(../libs/owlcarousel/owl.carousel.min.css);
@import url(../libs/fancybox/fancybox.min.css);
@import url(../libs/wow-js/animate.css);
@import url(../libs/animsition/animsition.min.css);
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: font-url("../fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: font-url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: font-url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: font-url("../fonts/Montserrat/Montserrat-Mediumr.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: font-url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: font-url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  src: font-url("../fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: font-url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  text-align: left;
  font-size: 16px;
  line-height: 31px;
  font-weight: 300;
  min-width: 320px;
  color: #000;
  overflow-x: hidden;
  position: relative;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

body.overflow {
  overflow: hidden;
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 27px;
  }
}

.up {
  text-transform: uppercase;
}

b,
strong {
  font-weight: bold;
}

ul, ol {
  list-style-type: none;
  margin: 0;
}

a, a:focus, a:active, input, button, select, .slick-slide {
  outline: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-weight: 300;
}

@media (max-width: 767px) {
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }
}

h1, h2 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  h1, h2 {
    font-size: 22px;
  }
}

p {
  margin-bottom: 30px;
  color: #626262;
}

@media (max-width: 767px) {
  p {
    margin-bottom: 20px;
  }
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 575px) {
  .container {
    padding: 0 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 728px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 952px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding: 0 50px;
  }
}

main {
  position: relative;
  padding: 175px 0 95px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

@media (max-width: 991px) {
  main {
    padding: 165px 0 80px;
  }
}

@media (max-width: 767px) {
  main {
    padding: 95px 0;
  }
}

.pagePadding, .about-main, .contacts, .portfolio, .project-page, .textpage {
  padding-top: 73px;
}

@media (max-width: 991px) {
  .pagePadding, .about-main, .contacts, .portfolio, .project-page, .textpage {
    padding-top: 77px;
  }
}

@media (max-width: 767px) {
  .pagePadding, .about-main, .contacts, .portfolio, .project-page, .textpage {
    padding-top: 50px;
  }
}

.clearfix {
  display: none;
  position: relative;
}

.clearfix::before, .clearfix::after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.btn {
  cursor: pointer;
  outline: none;
  width: 230px;
  height: 64px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  background: #000;
  color: #fff;
  border: 0;
  text-decoration: none !important;
}

.btn:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  left: -17px;
  top: -17px;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: #fff;
  transition: transform 250ms ease;
  pointer-events: none;
}

.btn:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  bottom: -13px;
  right: -13px;
  background: #fff;
  border: 1px solid #000;
  overflow: hidden;
  transition: all 250ms ease;
  pointer-events: none;
}

.btn:hover:before {
  transform: scale(1.3);
}

.btn:hover:after {
  bottom: -5px;
  right: -5px;
}

@media (max-width: 767px) {
  .btn {
    margin: 0 auto;
  }
}

.loadmore {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 992px) {
  .loadmore {
    margin-bottom: 92px;
  }
}

@media (max-width: 991px) {
  .loadmore {
    margin-bottom: 80px;
  }
}

.btn-loadmore {
  cursor: pointer;
  outline: none;
  width: 240px;
  height: 65px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  border: 1px solid #9f9f9f;
  transition: color 300ms ease;
}

.btn-loadmore span, .btn-loadmore:before {
  z-index: 1;
}

.btn-loadmore:after {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  transition: all 300ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  overflow: hidden;
}

.btn-loadmore:hover {
  color: #fff;
}

.btn-loadmore:hover:after {
  width: 100%;
  left: 0;
}

input, textarea {
  width: 100%;
  height: 44px;
  border: 0;
  border-bottom: 1px solid #000;
  padding: 0;
  font-size: 18px;
  line-height: 25px;
  outline: none;
  transition: border-color .2s;
  background: transparent;
  opacity: 1 !important;
  font-family: "Montserrat", sans-serif !important;
  color: #000;
  -webkit-appearance: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-appearance: none;
}

@media (max-width: 767px) {
  input, textarea {
    padding-left: 15px;
    font-size: 14px;
  }
}

input:focus, textarea:focus {
  border-color: #e7e7e7;
}

input:focus ~ label .char, textarea:focus ~ label .char {
  top: -20px;
  color: #e7e7e7 !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #000;
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
  height: 34px;
  margin-top: 10px;
}

label {
  cursor: unset;
}

.label-group {
  max-width: 295px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 48px;
  font-size: 14px;
  line-height: 21px;
  font-style: italic;
  color: #000;
  cursor: pointer;
}

@media (max-width: 767px) {
  .label-group {
    font-size: 12px;
  }
}

.label-group input {
  display: none;
}

.label-group .checkmark {
  position: absolute;
  left: 0;
  top: 3px;
  height: 24px;
  width: 24px;
  border: 1px solid #000;
}

@media (max-width: 1919px) {
  .label-group .checkmark {
    top: 0;
  }
}

.label-group .checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 5px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: .25s;
}

.label-group input:checked ~ .checkmark {
  border: 0;
  background-color: #000;
}

.label-group input:checked ~ .checkmark:after {
  opacity: 1;
}

.overlay {
  position: fixed;
  z-index: 998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

ol {
  list-style-type: decimal;
  padding-left: 25px;
  margin-bottom: 25px;
}

ol li {
  list-style-type: decimal;
  color: #626262;
  padding-left: 5px;
}

@media (max-width: 767px) {
  .fullScreen main {
    padding-bottom: 0;
  }
}

@media (max-width: 991px) {
  .fullScreen .header,
  .fullScreen .footer,
  .fullScreen .header-lang__inner {
    background: #fff;
  }
}

@media (max-width: 991px) {
  .fullScreen .footer {
    height: 80px;
  }
}

@media (max-width: 767px) {
  .fullScreen .footer {
    display: none;
  }
}

.fancybox-slide {
  padding: 0;
}

.tour3d {
  width: calc(100vw - 50px) !important;
  height: calc(100vh - 50px) !important;
}

@media (max-width: 575px) {
  .tour3d {
    width: 100vw !important;
    height: 100vh !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes showHeader {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes shake-custom {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
  10% {
    -webkit-transform: rotateZ(-30deg);
    transform: rotateZ(-30deg);
  }
  20% {
    -webkit-transform: rotateZ(15deg);
    transform: rotateZ(15deg);
  }
  30% {
    -webkit-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg);
  }
  40% {
    -webkit-transform: rotateZ(7.5deg);
    transform: rotateZ(7.5deg);
  }
  50% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  70% {
    -webkit-transform: rotate(-4.28571deg);
    transform: rotate(-4.28571deg);
  }
  80% {
    -webkit-transform: rotate(3.75deg);
    transform: rotate(3.75deg);
  }
  90% {
    -webkit-transform: rotate(-3.33333deg);
    transform: rotate(-3.33333deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 175px;
  background: #fafafa;
}

@media (min-width: 1200px) {
  .header {
    padding: 0 10px;
  }
}

@media (max-width: 991px) {
  .header {
    height: 165px;
  }
}

@media (max-width: 767px) {
  .header {
    height: 95px;
    padding: 15px 0;
  }
}

.header__container {
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1199px) {
  .header__container {
    padding: 0 10px;
  }
}

@media (max-width: 575px) {
  .header__container {
    padding: 0 20px;
  }
}

.header__main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .header__main {
    justify-content: flex-start;
  }
}

.header__block {
  display: flex;
  align-items: center;
}

.header__logo {
  display: block;
  margin-right: 88px;
  width: 106px;
  height: 106px;
  position: relative;
}

.header__logo svg, .header__logo img {
  width: 100%;
  height: 100%;
}

.header__logo:before {
  content: '';
  display: block;
  position: absolute;
  left: -38px;
  top: -38px;
  width: 76px;
  height: 76px;
  border-radius: 100%;
  background: #fafafa;
  transition: transform .3s ease;
}

@media (max-width: 991px) {
  .header__logo:before {
    background: #fff;
  }
}

@media (max-width: 767px) {
  .header__logo:before {
    width: 46px;
    height: 46px;
    left: -23px;
    top: -23px;
  }
}

.header__logo:hover:before {
  transform: scale(1.2);
}

@media (max-width: 991px) {
  .header__logo {
    margin-right: 50px;
  }
}

@media (max-width: 767px) {
  .header__logo {
    width: 65px;
    height: 65px;
    margin-right: 30px;
  }
}

@media (max-width: 991px) {
  .header-nav {
    display: none;
  }
}

.header-nav__list {
  display: flex;
}

.header-nav__item {
  margin-right: 72px;
}

.header-nav a {
  font-size: 18px;
  text-decoration: none;
  display: block;
  position: relative;
}

.header-nav a:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -3px;
  transform: translateX(-50%);
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: 0.2s ease;
  pointer-events: none;
}

.header-nav a:hover:before, .header-nav a.active:before {
  width: 100%;
}

.header-socials {
  display: flex;
}

@media (max-width: 767px) {
  .header-socials {
    display: none;
  }
}

.header-socials__item {
  width: 44px;
  height: 44px;
  border: 1px solid #e7e7e7;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  transition: 0.2s ease;
}

.header-socials__item img, .header-socials__item svg {
  width: 18px;
  height: 18px;
}

.header-socials__item path {
  transition: 0.2s ease;
}

.header-socials__item:hover {
  background: #000;
}

.header-socials__item:hover path {
  fill: #fff;
}

.header-lang {
  width: 84px;
  height: 44px;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  position: relative;
  user-select: none;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .header-lang {
    font-size: 13px;
    width: 65px;
    height: 36px;
  }
}

.header-lang__inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 22px;
  border: 1px solid #e7e7e7;
  overflow: hidden;
  transition: box-shadow .2s;
  background: #fafafa;
}

@media (max-width: 767px) {
  .header-lang__inner {
    border-radius: 18px;
  }
}

.header-lang__current {
  width: 100%;
  height: 42px;
  padding: 14px 23px;
  cursor: pointer;
  position: relative;
  transition: background-color .2s ease, color .2s ease;
}

@media (max-width: 767px) {
  .header-lang__current {
    padding: 9px 18px;
    height: 34px;
  }
}

.header-lang__current:after {
  content: '';
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid #000;
  transition: border-left-color .2s ease, transform .2s ease;
  pointer-events: none;
}

@media (max-width: 767px) {
  .header-lang__current:after {
    right: 12px;
  }
}

.header-lang__current:hover {
  background-color: #000;
  color: #fff;
}

.header-lang__current:hover:after {
  border-left-color: #fff;
}

.header-lang__dropdown {
  display: none;
  width: 100%;
}

.header-lang__item, .header-lang li {
  width: 100%;
  height: 42px;
  padding: 14px 23px;
  cursor: pointer;
  border-top: 1px solid #e7e7e7;
  transition: 0.2s ease;
}

@media (max-width: 767px) {
  .header-lang__item, .header-lang li {
    padding: 9px 18px;
    height: 34px;
  }
}

.header-lang__item:hover, .header-lang li:hover {
  background: #000;
  color: #fff;
}

.header-lang__item:hover a, .header-lang li:hover a {
  color: #ffffff;
  text-decoration: none;
}

.header-lang--active .header-lang__current:after {
  transform: translateY(-3px) rotate(90deg);
}

.header-lang--active .header-lang__inner {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header--fixed {
  position: fixed;
  top: 0;
  animation: showHeader .5s;
  width: 100%;
  height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.header--fixed .header__logo {
  height: 60px;
}

.header--fixed .header__logo:before {
  width: 43px;
  height: 43px;
  left: 1px;
  top: -21px;
}

@media (max-width: 767px) {
  .header--fixed .header__logo:before {
    left: -20px;
  }
}

.hamburger {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .hamburger {
    display: none;
  }
}

.hamburger-box {
  width: 25px;
  height: 18px;
  position: relative;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 2px;
  background: #000000;
}

.hamburger-inner {
  transition: transform .3s ease, top .2s .3s;
}

.hamburger-inner:before, .hamburger-inner:after {
  content: '';
  display: block;
}

.hamburger-inner:before {
  width: 20px;
  left: 5px;
  top: 8px;
  transition: top .3s .2s, width .3s .2s, opacity .3s .2s;
}

.hamburger-inner:after {
  left: 0;
  top: 16px;
  transition: transform .3s ease, top .2s .3s;
}

.hamburger.active .hamburger-inner {
  top: 8px;
  transform: rotate(225deg);
  transition: top .2s, transform .3s .2s ease;
}

.hamburger.active .hamburger-inner:after {
  top: 0;
  transform: rotate(90deg);
  transition: top .2s, transform .3s .2s ease;
}

.hamburger.active .hamburger-inner:before {
  top: 0;
  width: 0;
  transition: top .2s, width .1s, opacity .1s;
}

.mobile-menu {
  position: fixed;
  z-index: 999;
  top: 0;
  right: -260px;
  width: 260px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: height .25s ease;
  background: #fafafa;
  padding: 165px 0 95px;
}

@media (max-width: 767px) {
  .mobile-menu {
    padding: 95px 0 0;
  }
}

.mobile-menu__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 55px 50px 30px;
}

.mobile-menu__nav li {
  margin-bottom: 40px;
  margin-right: 0;
}

.mobile-menu__nav a {
  text-decoration: none;
  position: relative;
}

.mobile-menu__nav a:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  display: block;
  width: 0;
  height: 2px;
  background: #000;
  transition: 0.2s ease;
}

.mobile-menu__nav a:hover:before, .mobile-menu__nav a.active:before {
  width: 100%;
}

.mobile-menu__socials {
  display: flex !important;
  flex-wrap: wrap;
}

.mobile-menu__title {
  font-weight: 500;
  margin-bottom: 35px;
}

.footer {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 95px;
  background: #fafafa;
}

@media (max-width: 767px) {
  .footer {
    z-index: 997;
  }
}

.footer .container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer .copyright {
  font-size: 14px;
}

.projects-grid {
  width: 100%;
  position: relative;
  margin-bottom: 53px;
  display: block;
}

@media (max-width: 767px) {
  .projects-grid {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .projects-grid__container {
    max-width: 100%;
    padding: 0;
  }
}

.projects-grid .owl-item {
  height: calc(100vh - 95px);
  height: calc(var(--vh, 1vh) * 100 - 95px);
  transition: height .25s ease;
}

.projects-grid .owl-dots {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.projects-grid .owl-dot {
  width: 30px;
  height: 30px;
  padding: 13px 0;
}

.projects-grid .owl-dot:not(:last-child) {
  margin-right: 10px;
}

.projects-grid .owl-dot span {
  display: block;
  width: 100%;
  height: 4px;
  background: #fff !important;
}

.projects-grid .owl-dot.active span {
  background: #000 !important;
}

.project-item {
  visibility: hidden;
  display: block;
  text-decoration: none;
  position: relative;
  text-align: center;
  color: #fff;
  overflow: hidden;
}

@media (min-width: 768px) {
  .project-item {
    margin-bottom: 20px;
    float: left;
  }
}

@media (min-width: 992px) {
  .project-item {
    margin-bottom: 30px;
  }
  .project-item:nth-of-type(6n + 1), .project-item:nth-of-type(6n + 5) {
    width: calc(100% / 3 * 2 - 30px);
    height: calc(40vw - 94px);
  }
  .project-item:nth-of-type(6n + 2), .project-item:nth-of-type(6n + 3), .project-item:nth-of-type(6n + 4), .project-item:nth-of-type(6n + 6) {
    width: calc(100% / 3);
    height: 20vw;
  }
  .project-item:nth-of-type(6n + 1), .project-item:nth-of-type(6n + 4), .project-item:nth-of-type(6n + 6) {
    margin-right: 30px;
  }
  .project-item:nth-of-type(6n + 5) {
    float: right;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .project-item:nth-of-type(2n + 1) {
    margin-right: 20px;
  }
  .project-item:nth-of-type(6n + 1), .project-item:nth-of-type(6n + 4), .project-item:nth-of-type(6n + 5) {
    width: 440px;
    height: 340px;
  }
  .project-item:nth-of-type(6n + 2), .project-item:nth-of-type(6n + 3), .project-item:nth-of-type(6n + 6) {
    width: 268px;
    height: 380px;
  }
  .project-item:nth-of-type(6n + 1) {
    height: 380px;
  }
  .project-item:nth-of-type(1) {
    height: 340px;
  }
  .project-item:nth-of-type(2) {
    height: 500px;
  }
  .project-item:nth-of-type(6n + 6) {
    margin-top: -40px;
  }
}

@media (max-width: 767px) {
  .project-item {
    width: 100vw;
    padding-top: 87.5%;
  }
  .project-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.project-item__logo {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.project-item__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.7s ease;
}

.project-item__overlay {
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .project-item__overlay {
    left: 0 !important;
    top: 0 !important;
  }
}

.project-item__content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  max-width: 327px;
  max-height: calc(100% - 30px);
  overflow: hidden;
}

.project-item__title {
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  max-height: 30px;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .project-item__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    max-height: 48px;
  }
}

.project-item__text {
  font-size: 16px;
  line-height: 30px;
}

.project-item:hover .project-item__logo img {
  transform: scale(1.05);
}

.project-item:hover .project-item__overlay {
  opacity: 1;
}

.about {
  overflow: hidden;
}

.about__container {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1199px) {
  .about__container {
    flex-direction: column;
  }
}

.about__block {
  width: 100%;
  visibility: hidden;
}

@media (min-width: 1200px) {
  .about-main {
    width: 620px;
    padding-top: 43px;
    overflow: hidden;
    height: calc(100vh - 330px);
    margin: 30px auto;
    overflow: auto;
    padding-right: 15px;
  }
  .about-main::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
  .about-main::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .about-main::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 5px;
  }
  .about-main::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

@media (max-width: 1199px) {
  .about-main {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-width: 575px) {
  .about-main {
    padding-left: 0;
    padding-right: 0;
  }
}

.about-main__img {
  width: 100vw;
  margin-bottom: 30px;
}

.about-main__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 1200px) {
  .about-main__img {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .about-main__img {
    transform: translateX(calc(416px - 50vw));
    height: 600px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about-main__img {
    transform: translateX(calc(304px - 50vw));
    height: 600px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .about-main__img {
    transform: translateX(calc(210px - 50vw));
    height: 500px;
  }
}

@media (max-width: 575px) {
  .about-main__img {
    transform: translateX(-20px);
    height: 400px;
  }
}

.about-gallery {
  position: relative;
  margin-left: 30px;
  width: calc((100vh - 270px) * 1.123);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .about-gallery {
    height: calc(100vh - 270px);
  }
}

@media (max-width: 1199px) {
  .about-gallery {
    width: 100%;
    margin-left: 0;
    margin-bottom: 60px;
  }
}

@media (max-width: 575px) {
  .about-gallery {
    display: block;
  }
}

.about-gallery__img {
  width: calc((100% - 60px) / 3);
  height: calc(30% - 40px);
  overflow: hidden;
  display: block;
  position: relative;
}

@media (max-width: 1199px) {
  .about-gallery__img {
    width: calc(50% - 10px);
    height: 280px;
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .about-gallery__img {
    height: 210px;
  }
}

@media (max-width: 575px) {
  .about-gallery__img {
    width: 100vw;
    transform: translateX(-20px);
  }
}

.about-gallery__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.7s ease;
}

.about-gallery__img:hover img {
  transform: scale(1.1);
}

@media (min-width: 1200px) {
  .about-gallery__img:nth-child(n + 5) {
    display: none;
  }
}

.about-gallery__img--main {
  width: 100%;
  height: 70%;
  margin-bottom: 30px;
  margin-top: 10px;
}

@media (max-width: 1199px) {
  .about-gallery__img--main {
    display: none;
  }
}

.about-gallery__img .video-btn {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  display: block;
  width: calc(15vh - 60px);
  height: calc(15vh - 60px);
  background: #fff;
  transition: .3s ease;
}

.about-gallery__img .video-btn:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 25%;
  height: 25%;
  background: url(../img/icons/play.svg) center no-repeat #fff;
  background-size: contain;
}

@media (max-width: 1199px) {
  .about-gallery__img .video-btn {
    width: 100px;
    height: 100px;
  }
}

.about-gallery__img--video {
  position: relative;
}

.about-gallery__img--video:before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.about-gallery__img--video:hover img {
  transform: none !important;
}

.about-gallery__img--video:hover .video-btn {
  transform: translate(-50%, -50%) scale(1.2);
}

.contacts {
  min-height: calc(100vh - 270px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contacts__container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0 120px;
}

@media (max-width: 1199px) {
  .contacts__container {
    flex-direction: column;
    padding: 0 80px;
  }
}

@media (max-width: 767px) {
  .contacts__container {
    padding: 0 20px;
  }
}

@media (max-width: 1199px) {
  .contacts__block {
    width: 100%;
  }
}

.contacts-list {
  min-width: 400px;
  max-width: 530px;
  margin-right: 50px;
}

@media (max-width: 1199px) {
  .contacts-list {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 104px;
  }
}

.contacts-list__item {
  padding-left: 55px;
  margin-bottom: 40px;
  position: relative;
  width: fit-content;
  font-size: 20px;
  line-height: 33px;
}

@media (max-width: 767px) {
  .contacts-list__item {
    font-size: 16px;
  }
}

.contacts-list__item i {
  font-size: 16px;
}

@media (max-width: 767px) {
  .contacts-list__item {
    padding-left: 75px;
  }
}

.contacts-list__item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  display: block;
  width: 15px;
  height: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
}

@media (max-width: 767px) {
  .contacts-list__item:before {
    left: 20px;
  }
}

.contacts-list__item--call:before {
  background-image: url(../img/icons/call.svg);
}

.contacts-list__item--mail:before {
  background-image: url(../img/icons/mail.svg);
}

.contacts-list__item--map:before {
  background-image: url(../img/icons/map.svg);
}

.contacts-list__item:hover:before {
  animation: shake-custom .8s ease;
}

.contacts-list__link {
  font-size: 20px;
}

@media (max-width: 767px) {
  .contacts-list__link {
    font-size: 16px;
  }
}

.contacts-form {
  max-width: 695px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .contacts-form {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .contacts-form {
    justify-content: center;
  }
}

.contacts-form__row {
  width: calc(50% - 15px);
  position: relative;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .contacts-form__row {
    width: 100%;
  }
}

.contacts-form__row--max {
  width: 100% !important;
}

.contacts-form__row label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  pointer-events: none;
  color: #000;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  letter-spacing: 0;
  text-transform: none;
  transition: 0.2s ease all;
}

@media (max-width: 767px) {
  .contacts-form__row label {
    left: 15px;
  }
}

.contacts-form__row label .char {
  position: relative;
  transition: 0.15s;
  transition-delay: calc(20ms * var(--char-index));
  top: 0;
}

.contacts-form__row label.valid .char {
  top: -20px;
  color: #42b34b;
}

.contacts-form__info {
  color: gray;
  font-size: 14px;
}

@media (max-width: 1919px) {
  .contacts-form__info {
    font-size: 12px;
    line-height: 1.5;
  }
}

@media (max-width: 767px) {
  .contacts-form__info {
    width: 100%;
  }
}

.contacts-form__text {
  margin-bottom: 17px;
}

.contacts-form__accept {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.contacts-form__accept:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid gold;
  margin-right: 8px;
}

@media (max-width: 767px) {
  .contacts-form__policy {
    width: 100%;
    margin-bottom: 50px;
  }
}

.contacts__map {
  width: 100%;
  height: 300px;
  margin-top: 70px;
}

.contacts__map iframe {
  width: 100%;
  height: 100%;
}

.main-page .projects-grid {
  margin-bottom: -30px;
  padding: 24px 0;
}

@media (max-width: 991px) {
  .main-page .projects-grid {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .main-page .projects-grid {
    overflow: hidden;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .main-page .project-item:nth-child(n + 4) {
    display: none;
  }
  .main-page .project-item:nth-of-type(n + 1) {
    height: calc(100vh - 318px);
  }
  .main-page .project-item:nth-of-type(n + 2), .main-page .project-item:nth-of-type(n + 3) {
    height: calc((100vh - 348px) / 2);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-page .project-item:nth-of-type(n + 1) {
    width: 470px;
    height: calc((100vh - 245px) / 3 * 2 - 10px);
  }
  .main-page .project-item:nth-of-type(n + 2) {
    width: 228px;
    height: calc((100vh - 245px) / 3 * 2 - 10px);
  }
  .main-page .project-item:nth-of-type(n + 3) {
    width: 228px;
    height: calc((100vh - 245px) / 3 - 10px);
  }
  .main-page .project-item:nth-of-type(n + 4) {
    width: 470px;
    height: calc((100vh - 245px) / 3 - 10px);
  }
}

@media (max-width: 767px) {
  .main-page .project-item {
    padding: 0;
    height: 100%;
  }
}

.portfolio__content {
  padding-left: 200px;
  width: calc(100% / 3 * 2 - 30px);
  margin-bottom: 73px;
}

@media (max-width: 1199px) {
  .portfolio__content {
    padding-left: 100px;
  }
}

@media (max-width: 991px) {
  .portfolio__content {
    width: 436px;
    padding-left: 60px;
    margin-bottom: 77px;
  }
}

@media (max-width: 767px) {
  .portfolio__content {
    position: relative;
    width: 100%;
    padding: 0;
    margin-bottom: 50px;
  }
}

@media (min-width: 992px) {
  .portfolio .project-item:nth-of-type(6n + 2) {
    margin-top: -124px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .portfolio .project-item:nth-of-type(2) {
    margin-top: -160px;
  }
}

@media (max-width: 991px) {
  .project-page {
    padding-top: 50px;
  }
}

.project-page__content {
  position: relative;
  visibility: hidden;
  padding-left: 200px;
  width: calc(100% / 3 * 2 - 30px);
  margin-bottom: 73px;
}

@media (max-width: 1199px) {
  .project-page__content {
    padding-left: 100px;
  }
}

@media (max-width: 991px) {
  .project-page__content {
    width: 436px;
    padding-left: 60px;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .project-page__content {
    position: relative;
    width: 100%;
    padding: 0;
    margin-bottom: 60px;
  }
}

.project-page__back-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
}

.project-page__back-link:before {
  content: '';
  position: absolute;
  left: 2px;
  top: 10px;
  display: block;
  width: 10px;
  height: 10px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(45deg);
  transition: left .25s ease;
}

.project-page__back-link:hover:before {
  left: -8px;
}

@media (max-width: 767px) {
  .project-page__back-link {
    display: none;
  }
}

.project-page h1 {
  margin-bottom: 40px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .project-page h1 {
    font-size: 22px;
    margin-bottom: 25px;
  }
}

.project-page__text {
  max-width: 600px;
  margin-bottom: 40px;
  line-height: 31px;
  height: 62px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .project-page__text {
    max-width: 340px;
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 27px;
  }
}

@media (max-width: 767px) {
  .project-page__text {
    height: auto;
    margin-bottom: 40px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .project-page .project-item:nth-of-type(6n + 2) {
    margin-top: -124px;
  }
  .project-page .project-item:nth-of-type(1) {
    height: calc(46vw - 236px);
  }
  .project-page .project-item:nth-of-type(2), .project-page .project-item:nth-of-type(3) {
    height: 23vw;
  }
  .project-page .project-item:nth-of-type(2) {
    margin-top: -266px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .project-page .project-item:nth-of-type(2) {
    margin-top: -278px;
    height: 620px;
  }
}

@media (max-width: 991px) {
  .project-page .project-item__overlay {
    display: none;
  }
}

.textpage {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .textpage {
    height: calc(100vh - 300px);
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .textpage__container {
    padding: 0 80px;
  }
}

@media (min-width: 1200px) {
  .textpage__container {
    max-width: 1000px;
  }
}

.textpage h1 {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .textpage h1 {
    margin-bottom: 30px;
  }
}

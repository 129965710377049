.footer {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 95px;
  background: $bg;
  @include media-down(sm) {
    z-index: 997;
  }

  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .copyright {
    font-size: 14px;
  }
}

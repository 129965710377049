.contacts {  
  @extend .pagePadding;

  min-height: calc(100vh - 270px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 0 120px;
    @include media-down(lg) {
      flex-direction: column;
      padding: 0 80px;
    }
    @include media-down(sm) {padding: 0 20px;}
  }

  &__block {
    @include media-down(lg) {width: 100%;}
  }

  &-list{
    min-width: 400px;
    max-width: 530px;
    margin-right: 50px;
    @include media-down(lg) {
      min-width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 104px;
    }
    
    &__item {
      padding-left: 55px;
      margin-bottom: 40px;
      position: relative;
      width: fit-content;
      font-size: 20px;
      line-height: 33px;
      @include media-down(sm) {font-size: 16px;}
      i {font-size: 16px;}
      @include media-down(sm) {
        padding-left: 75px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0; top: 7px;
        display: block;
        width: 15px; height: 15px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px;
        @include media-down(sm) {
          left: 20px;
        }
      }
      &--call:before {background-image: url(../img/icons/call.svg);}
      &--mail:before {background-image: url(../img/icons/mail.svg);}
      &--map:before {background-image: url(../img/icons/map.svg);}

      &:hover:before {
        animation: shake-custom .8s ease;
      }
    }
    &__link {
      font-size: 20px;
      @include media-down(sm) {font-size: 16px;}
    }
  }

  &-form {
    max-width: 695px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include media-down(md) {max-width: 100%;}
    @include media-down(sm) {justify-content: center;}
    &__row {
      width: calc(50% - 15px);
      @include media-down(sm) {width: 100%;}
      &--max {width: 100% !important;}
      position: relative;
      margin-bottom: 32px;
      label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        pointer-events: none;
        color: #000;
        font-weight: normal;
        font-style: italic;
        font-size: 14px;
        letter-spacing: 0;
        text-transform: none;
        transition: 0.2s ease all;
        @include media-down(sm) {
          left: 15px;
        }
        .char {
          position: relative;
          transition: 0.15s;
          transition-delay: calc(20ms * var(--char-index));
          top: 0;

        }
        &.valid .char {
          top: -20px;
          color: rgb(66, 179, 75);
        }
      }
    }
    &__info {
      color: gray;
      font-size: 14px;
      @include media-down(xl) {
        font-size: 12px;
        line-height: 1.5;
      }
      @include media-down(sm) {width: 100%;}
    }
    &__text {
      margin-bottom: 17px;
    }
    &__accept {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 16px; height: 16px;
        border-radius: 2px;
        border: 1px solid gold;
        margin-right: 8px;
      }
    }
    &__policy {
      @include media-down(sm) {
        width: 100%;
        margin-bottom: 50px;
      }
    }
  }

  &__map {
    width: 100%;
    height: 300px;
    margin-top: 70px;
    iframe {
      width: 100%; 
      height: 100%;
    }
  }
}
a {
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include media-down(xs) {padding: 0 20px;}
  @include media-only(sm) {max-width: 540px;}
  @include media-only(md) {max-width: 728px;}
  @include media-only(lg) {max-width: 952px;}
  @include media-up(xl) {padding: 0 50px;}
}

main {
  position: relative;
  padding: 175px 0 95px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  @include media-down(md) {
    padding: 165px 0 80px;
  }
  @include media-down(sm) {
    padding: 95px 0;
  }
}

.pagePadding {
  padding-top: 73px; 
  @include media-down(md) {padding-top: 77px;}
  @include media-down(sm) {padding-top: 50px;}
}

.clearfix {
  display: none;
  position: relative;
  &::before, 
  &::after { 
    content: "";
    display: block; 
    height: 0; 
    clear: both;
  }
}

.btn {
  cursor: pointer;
  outline: none;
  width: 230px; height: 64px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  background:#000;
  color: #fff;
  border: 0;
  text-decoration: none !important;
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: -17px; top: -17px;
    width: 34px; height: 34px;
    border-radius: 100%;
    background: #fff;
    transition: transform 250ms ease;
    pointer-events: none;
  }

  &:after {
    content: '';
    width: 100%; height: 100%;
    position: absolute;
    z-index: -1;
    bottom: -13px;  right: -13px;
    background: #fff;
    border: 1px solid #000;
    overflow: hidden;
    transition: all 250ms ease;
    pointer-events: none;
  }

  &:hover {
    &:before {transform: scale(1.3);}
    &:after {bottom: -5px; right: -5px;}
  }

  @include media-down(sm) {
    margin: 0 auto;
  }
}

.loadmore {
  display: flex;
  justify-content: center;
  width: 100%;
  @include media-up(lg) {
    margin-bottom: 92px;
  }
  @include media-down(md) {
    margin-bottom: 80px;
  }
}
.btn-loadmore {
  cursor: pointer;
  outline: none;
  width: 240px; height: 65px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  border: 1px solid #9f9f9f;
  transition: color 300ms ease;
  span,
  &:before {z-index: 1;}

  &:after {
    content: '';
    width: 0; height: 100%;
    position: absolute;
    top: 0;  left: auto; right: 0;
    transition: all 300ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    overflow: hidden;
  }
  &:hover {
    color: #fff;
    &:after {width: 100%; left: 0;}
  }
}

input, textarea {
  width: 100%;
  height: 44px;
  border: 0;
  border-bottom: 1px solid #000;
  padding: 0;
  font-size: 18px;
  line-height: 25px;
  outline: none;
  transition: border-color .2s;
  background: transparent;
  opacity: 1 !important;
  font-family: $font !important;
  color: #000;
  @include media-down(sm) {
    padding-left: 15px;
    font-size: 14px;
  }
  &:focus {
    border-color: $border;
    & ~ label .char {
      top: -20px;
      color: $border !important;
    }
  }

  // Safari fix
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #000;
    transition: background-color 5000s ease-in-out 0s;
  }
  // Iphone fix
  -webkit-appearance: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-appearance: none;
}
textarea {
  height: 34px;
  margin-top: 10px;
}

label {cursor: unset;}

.label-group {
  max-width: 295px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 48px;
  font-size: 14px;
  line-height: 21px;
  font-style: italic;
  color: #000;
  cursor: pointer;
  @include media-down(sm) {
    font-size: 12px;
  }
  input {
    display: none;
  }
  .checkmark {
    position: absolute;
    left: 0; top: 3px;
    height: 24px; width: 24px;
    border: 1px solid #000;
    @include media-down(xl) {top: 0;}
    &:after {
      content: "";
      position: absolute;
      left: 8px; top: 5px;
      width: 7px; height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
      transition: .25s;
    }
  }
  input:checked ~ .checkmark {
    border: 0;
    background-color: #000;
    &:after {opacity: 1; }
  }
}

.overlay {
  position: fixed;
  z-index: 998;
  left: 0; top: 0;
  width: 100%; height: 100%;
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

ol {
	list-style-type: decimal;
	padding-left: 25px;
	margin-bottom: 25px;
}

ol li {
	list-style-type: decimal;
	color: #626262;
	padding-left: 5px;
}

.fullScreen {
  main {
    @include media-down(sm) {
      padding-bottom: 0;
    }
  }
  .header,
  .footer,
  .header-lang__inner {
    @include media-down(md) {
      background: #fff;
    }
  }
  .footer {
    @include media-down(md) {
      height: 80px;
    }
    @include media-down(sm) {
      display: none;
    }
  }
}

.fancybox-slide {
  padding: 0;
}
.tour3d {
  width: calc(100vw - 50px) !important;
  height: calc(100vh - 50px) !important;
  @include media-down(xs) {
    width: 100vw !important;
    height: 100vh !important;
  }
}
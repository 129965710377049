@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}
@keyframes showHeader {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
@keyframes shake-custom {
  0% {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
  10% {
    -webkit-transform: rotateZ(-30deg);
    transform: rotateZ(-30deg);
  }
  20% {
    -webkit-transform: rotateZ(15deg);
    transform: rotateZ(15deg);
  }
  30% {
    -webkit-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg);
  }
  40% {
    -webkit-transform: rotateZ(7.5deg);
    transform: rotateZ(7.5deg);
  }
  50% {
    -webkit-transform: rotate(-6deg);
    transform: rotate(-6deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  70% {
    -webkit-transform: rotate(-4.28571deg);
    transform: rotate(-4.28571deg);
  }
  80% {
    -webkit-transform: rotate(3.75deg);
    transform: rotate(3.75deg);
  }
  90% {
    -webkit-transform: rotate(-3.33333deg);
    transform: rotate(-3.33333deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
.project-page {
  @extend .pagePadding;
  @include media-down(md) {
    padding-top: 50px;
  }
  &__content {
    position: relative;
    visibility: hidden;
    padding-left: 200px;
    width: calc(100% / 3 * 2 - 30px);
    margin-bottom: 73px;
    @include media-down(lg) {
      padding-left: 100px;
    }
    @include media-down(md) {
      width: 436px;
      padding-left: 60px;
      margin-bottom: 50px;
    }
    @include media-down(sm) {
      position: relative;
      width: 100%;
      padding: 0;
      margin-bottom: 60px;
    }
  }
  &__back-link {
    position: absolute;
    left: 0; top: 0;
    width: 30px; height: 30px;
    &:before {
      content: '';
      position: absolute;
      left: 2px; top: 10px;
      display: block;
      width: 10px; height: 10px;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      transform: rotate(45deg);
      transition: left .25s ease;
    }
    &:hover:before {
      left: -8px;
    }
    @include media-down(sm) {display: none;}
  }
  h1 {
    margin-bottom: 40px;
    @include media-only(md) {
      font-size: 22px;
      margin-bottom: 25px;
    }
  }
  &__text {
    max-width: 600px;
    margin-bottom: 40px;
    line-height: 31px;
    height: 62px;
    overflow: hidden;
    @include media-down(md) {
      max-width: 340px;
      margin-bottom: 25px;
      font-size: 14px;
      line-height: 27px;
    }
    @include media-down(sm) {
      height: auto;
      margin-bottom: 40px;
      max-width: 100%;
    }
  }
  .project-item {
    @include media-up(lg) {
      &:nth-of-type(6n + 2) {
        margin-top: -124px;
      }
      &:nth-of-type(1) {
        height: calc(46vw - 236px);
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        height: 23vw;
      }
      &:nth-of-type(2) {
        margin-top: -266px;
      }
    }
    @include media-only(md) {
      &:nth-of-type(2) {
        margin-top: -278px;
        height: 620px;
      }
    }
    @include media-down(md) {
      &__overlay {
        display: none;
      }
    }
  }
}
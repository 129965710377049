.about {
  overflow: hidden;
  &__container {
    display: flex;
    justify-content: flex-end;
    @include media-down(lg) {
      flex-direction: column;
    }
  }
  &__block {
    width: 100%;
    visibility: hidden;
  }
  &-main {
    @extend .pagePadding;

    @include media-up(xl) {
      width: 620px;
      padding-top: 43px;
      overflow: hidden;
      height: calc(100vh - 330px);
      margin: 30px auto;
      overflow: auto;
      padding-right: 15px;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #bbb;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #888;
      }
    }

    @include media-down(lg) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @include media-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }

    &__img {
      width: 100vw;
      margin-bottom: 30px;
      img {width: 100%; height: 100%; object-fit: cover}
      @include media-up(xl) {display: none;}
      @include media-only(lg) {
        transform: translateX(calc(416px - 50vw));
        height: 600px;
      }
      @include media-only(md) {
        transform: translateX(calc(304px - 50vw));
        height: 600px;
      }
      @include media-only(sm) {
        transform: translateX(calc(210px - 50vw));
        height: 500px;
      }
      @include media-down(xs) {
        transform: translateX(-20px);
        height: 400px;
      }
    }
  }

  &-gallery {
    position: relative;
    margin-left: 30px;
    width: calc((100vh - 270px) * 1.123);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include media-up(xl) {
      height: calc(100vh - 270px);
    }
    @include media-down(lg) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 60px;
    }
    @include media-down(xs) {display: block;}

    &__img {
      width: calc((100% - 60px) / 3);
      height: calc(30% - 40px);
      overflow: hidden;
      display: block;
      position: relative;

      @include media-down(lg) {
        width: calc(50% - 10px);
        height: 280px;
        margin-bottom: 20px;
      }
      @include media-down(md) {height: 210px;}
      @include media-down(xs) {
        width: 100vw;
        transform: translateX(-20px);
      }

      img {
        width: 100%; 
        height: 100%; 
        object-fit: cover;
        transition: 0.7s ease;
      }
      &:hover img {transform: scale(1.1);}

      @include media-up(xl) {
        &:nth-child(n + 5) {
          display: none;
        }
      }

      &--main {
        width: 100%;
        height: 70%;
        margin-bottom: 30px;
        margin-top: 10px;
        @include media-down(lg) {
          display: none;
        }
      }
      .video-btn {
        position: absolute;
        z-index: 1;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        display: block;
        width: calc(15vh - 60px); 
        height: calc(15vh - 60px);
        &:before {
          content: '';
          position: absolute;
          left: 50%; top: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 25%; height: 25%;
          background: url(../img/icons/play.svg) center no-repeat #fff;
          background-size: contain;
        }
        background: #fff;
        transition: .3s ease;
        @include media-down(lg) {
          width: 100px; height: 100px;
        }
      }
      &--video {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          z-index: 1;
          left: 0; top: 0;
          display: block;
          width: 100%; height: 100%;
          background: rgba(0, 0, 0, 0.5);
        }
        &:hover {
          img {transform: none !important;}
          .video-btn {
            transform: translate(-50%, -50%) scale(1.2);
          }
        }
      }
    }
  }
}


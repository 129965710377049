.portfolio {
  @extend .pagePadding;
  &__content {
    padding-left: 200px;
    width: calc(100% / 3 * 2 - 30px);
    margin-bottom: 73px;
    @include media-down(lg) {
      padding-left: 100px;
    }
    @include media-down(md) {
      width: 436px;
      padding-left: 60px;
      margin-bottom: 77px;
    }
    @include media-down(sm) {
      position: relative;
      width: 100%;
      padding: 0;
      margin-bottom: 50px;
    }
  }
  .project-item {
    @include media-up(lg) {
      &:nth-of-type(6n + 2) {
        margin-top: -124px;
      }
    }
    @include media-only(md) {
      &:nth-of-type(2) {
        margin-top: -160px;
      }
    }
  }
}
*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block
}

body {
  text-align: left;
  font-size: 16px;
  line-height: 31px;
  font-weight: 300;
  min-width: 320px;
  color: #000;
  overflow-x: hidden;
  position: relative;
  font-family: $font;
  overflow-x: hidden;
  &.overflow {overflow: hidden;}
  @include media-down(sm) {
    font-size: 14px;
    line-height: 27px;
  }
}

.up {
  text-transform: uppercase;
}

b,
strong {
  font-weight: bold;
}

ul, ol {
  list-style-type: none;
  margin: 0;
}

a, a:focus, a:active, input, button, select, .slick-slide {
  outline: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-weight: 300;
  @include media-down(sm) {
    text-align: center;
  }
}

h1, h2 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 50px;
  @include media-down(sm) {
    font-size: 22px;
  }
}

p {
  margin-bottom: 30px;
  color: #626262;
  @include media-down(sm) {
    margin-bottom: 20px;
  }
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}